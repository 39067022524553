import { Template } from '@dce-front/sdk-hodor';
import { Helmet } from 'react-helmet';
import type { ImmersiveMeta } from '../Immersive/types';
import { useMetas } from './useMetas';

type MetaTagsProps = {
  meta: ImmersiveMeta;
  displayTemplate: string;
  enableMetaSnippets?: boolean;
};

/**
 * Add meta tags for a template, values are determined by 'meta' property from hodor response
 */
export function MetaTags({
  meta,
  displayTemplate,
  enableMetaSnippets = true,
}: MetaTagsProps) {
  // image meta tag
  const urlImage: string | undefined = (
    [
      Template.DetailPage,
      Template.Quicktime,
      Template.DetailSeason,
      Template.DetailShow,
      Template.CreativeMedia,
      Template.DetailLight,
    ] as string[]
  ).includes(displayTemplate)
    ? meta.urlImage
    : undefined;

  const metas = useMetas({
    title: meta.title,
    description: meta.description,
    urlImage,
    isLargeImageMetaTwitter: displayTemplate === Template.DetailPage,
  });

  // add meta tags robots only if we have property robots in meta response
  const robotsMetas = meta.robots
    ? [{ name: 'robots', content: meta.robots }]
    : [];

  return (
    <Helmet
      meta={[...metas, ...robotsMetas]}
      title={metas.find((meta) => meta.property === 'title')?.content}
    >
      {enableMetaSnippets && meta.snippets && (
        <script type="application/ld+json">
          {JSON.stringify(meta.snippets)}
        </script>
      )}
    </Helmet>
  );
}
