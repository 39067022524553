import type {
  ApiV2Article,
  ApiV2DetailLight,
  ApiV2DetailV5,
} from '@dce-front/hodor-types';
import type { OfferLocation } from '@dce-front/sdk-core';
import { Template } from '@dce-front/sdk-hodor';
import type { AppKey } from '../../../config/application/types';
import type { UseInfiniteQueryHandlerData } from '../../helpers/hooks/useInfiniteQueryTemplate/types';
import { buildMeta } from '../../helpers/metaTags/buildMeta';
import { buildUpdatedMeta } from '../../helpers/metaTags/metaTags-helper';
import type { Meta } from '../../helpers/metaTags/types';
import { isDetailTemplate } from '../../helpers/page/page-helper';
import type { GenericOutput } from '../../services/types';

export type AllQueryHandlerResult = Partial<
  UseInfiniteQueryHandlerData<GenericOutput>
>;

const isApiV2DetailV5 = (
  data: AllQueryHandlerResult,
): data is ApiV2DetailV5 => {
  return (
    !!data.currentPage?.displayTemplate &&
    isDetailTemplate(data.currentPage?.displayTemplate) &&
    Boolean((data as ApiV2DetailV5).detail)
  );
};

const isApiV2Article = (data: AllQueryHandlerResult): data is ApiV2Article => {
  return data.currentPage?.displayTemplate === Template.CreativeMedia;
};

const isApiV2DetailLight = (
  data: AllQueryHandlerResult,
): data is ApiV2DetailLight => {
  return data.currentPage?.displayTemplate === Template.DetailLight;
};

export type FormatMetaParameters = {
  data: AllQueryHandlerResult;
  offerLocation: OfferLocation;
  appKey?: AppKey;
};

// TODO can we merge buildMeta and buildUpdatedMeta into one function ?
export const getFormattedMeta = ({
  data,
  offerLocation,
  appKey,
}: FormatMetaParameters): Meta | undefined => {
  const meta = data.pages?.[0]?.meta || data.meta;
  const currentPage = data.pages?.[0]?.currentPage || data.currentPage;

  if (!meta || !currentPage) {
    return;
  }

  const displayTemplate = currentPage.displayTemplate;
  const displayName = currentPage.displayName;

  let urlImage: string | undefined;

  if (isApiV2DetailV5(data)) {
    urlImage = data.detail.cover?.regularImage?.URLImage;
  }

  if (isApiV2Article(data)) {
    urlImage = data.informations?.mainMedia?.URLImage;
  }

  if (isApiV2DetailLight(data)) {
    urlImage = meta?.urlImage;
  }

  const metaParams = buildMeta({
    displayName,
    displayTemplate,
    appKey,
    metaParams: meta,
    urlImage,
  });

  const updatedMeta = buildUpdatedMeta({
    meta: metaParams,
    offerLocation,
    appKey,
  });

  return updatedMeta;
};
